<template>
    <el-dialog title="选择优惠券" :visible.sync="addDiscountVisible" width="90%" @close="close">
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="优惠券名称:">
                    <el-input placeholder="请输入优惠券名称" v-model="queryParams.name" clearable></el-input>
                </el-form-item>
                <!-- <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item style="float: right">
                    <el-button type="primary" @click="getDiscount()">搜索</el-button>
                </el-form-item>
            </el-form>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="id" width="50" align="center"></el-table-column>
                <el-table-column prop="name" label="优惠券名称" width="150" align="center"></el-table-column>
                <el-table-column prop="price" label="优惠劵价格" width="100" align="center"></el-table-column>
                <el-table-column prop="ctime" label="优惠券开始时间" width="180" align="center"></el-table-column>
                <el-table-column prop="etime" label="优惠券结束时间" width="180" align="center"></el-table-column>
                <el-table-column prop="full_price" label="满足金额" align="center"></el-table-column>
                <el-table-column prop="is_receive" label="是否可领取" width="150" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.is_receive === 2">可领取</el-tag>
                        <el-tag type="info" v-if="scope.row.is_receive === 1">不可领取</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="限制领取" prop="limit" align="center" width="90"></el-table-column>
                <el-table-column prop="resource" label="优惠券类型" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.resource === 0">全场</el-tag>
                        <el-tag type="primary" v-if="scope.row.resource === 1">品牌</el-tag>
                        <el-tag type="primary" v-if="scope.row.resource === 3">专场</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="ty" label="折扣类型" width="100" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.ty === 1">直减</el-tag>
                        <el-tag type="info" v-if="scope.row.ty === 2">折扣</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="resource_id" label="类型id" align="center"></el-table-column>
                <el-table-column label="状态" prop="state" align="center">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="stock" label="库存" width="100" align="center"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="submitProduct">提 交</el-button>
        </div>
    </el-dialog>
</template>
<script>
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
export default {
    name: 'Addproduct',
    components: {
        Pagination
    },
    props: ['addVisible', 'pGoodsList', 'state'], // 声明自定义属性
    data() {
        return {
            activeName: '商品分类',
            stretch: true,
            queryParams: {
                page: 1,
                pagesize: 10,
                state: 2
            },
            pageInfo: {},
            addDiscountVisible: false,
            title: "选择商品",
            loading: false,
            discountLis: [],
            searchDiscountList: [],
            selectDiscountList: [],
            brandList: [], //品牌列表
        };
    },
    created() {
        this.getDiscount()
    },
    watch: {
        addVisible(oldVal, newVal) {
            this.addDiscountVisible = this.addVisible
        },
    },
    methods: {
        handleSelectionChange(val) {
            this.selectDiscountList = val;
        },
        getDiscount() {
            this.loading = true;
            request.get('/coupon/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        val.ctime = deal.timestamp(val.ctime, 'unix');
                        val.etime = deal.timestamp(val.etime, 'unix');
                        return val;
                    });
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        close() {
            this.queryParams = {
                name: '',
                page: 1,
                pagesize: 10
            }
            this.searchDiscountList = []
            this.$emit('toggleAdd', false);

        },
        // 商品选择
        submitProduct() {
            this.queryParams = {
                page: 1,
                pagesize: 10
            }
            this.searchDiscountList = []
            this.close()
            if (this.selectDiscountList) {
                if (this.pGoodsList) {
                    this.discountLis = this.pGoodsList.concat(this.selectDiscountList);
                } else {
                    this.discountLis = this.selectDiscountList

                }
                var newArr = []
                // 商品去重处理
                for (var i = 0; i < this.discountLis.length; i++) {
                    if (!newArr.includes(this.discountLis[i])) {
                        newArr.push(this.discountLis[i]);
                    }
                }
                this.$emit('getAddGood', newArr);

            }
        },
        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            this.getDiscount();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            this.getDiscount()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            this.getDiscount()
        },
    }
}
</script>